import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTournamentsList } from "../actions";

// Define the request and response data interfaces
interface TournamentListRequestData {
    id: string;
    skip: number;
    limit: number;
    [key: string]: any;
}

interface TournamentListState {
    SelectedTournaments: TournamentListRequestData[];
    loading: boolean;
    error: string | null;
}

interface TournamentListData {
    success: boolean;
    data: TournamentListRequestData[];  
    countDocuments: number;  
    message?: string;  
}

// Initial state
const initialState: TournamentListState = {
    SelectedTournaments: [],
    loading: false,
    error: null,
};

// Create the slice
const selectedTournamentsListSlice = createSlice({
    name: "SelectedTournamentList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTournamentsList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getTournamentsList.fulfilled, (state, action: PayloadAction<{ data: TournamentListRequestData[]; countDocuments: number }>) => {
                state.loading = false;
                state.SelectedTournaments = action.payload.data;
            })
            .addCase(getTournamentsList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch tournament list";
            });
    },
});

export default selectedTournamentsListSlice.reducer;
