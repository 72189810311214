import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthServices from "../services/AuthServices";
import PlayerListService from '../services/PlayerListService';
import communityListServices from '../services/communityListServices';
import AdvertisementService from '../services/advertisementService';
import DashboardServices from '../services/DashboardServices';
import CommunityDetailsService from '../services/communityDetailsServices';
import TournamentService from '../services/TournamentService';
import FollowerFollowingService from '../services/FollowerFollowingService';
import PlayersDetailsServices from '../services/PlayersDetailsServices';
import UserPlayerService from '../services/UserPlayerService';
import FollowerFollowingPlayersService from '../services/FollowerFollowingPlayersService';
import FollowerFollowing from '../services/FollowerFollowingListServices';
import AgeGenderStatisticsService from '../services/AgeGenderStatisticsService';
import TopCitiesWithHighestServices from '../services/TopCitiesWithHighestServices';
import TournamentListServices from '../services/TournamentListServices';
import TournamentPointsTableServices from '../services/TournamentPointsTableServices';
import TouenamentPrizeCardServices from '../services/TouenamentPrizeCardServices';
import AllJoinTeamsOfTournamentServices from '../services/AllJoinTeamsOfTournamentServices';
import RuleServices from '../services/RuleServices';
import SponsorshipServices from '../services/SponsorshipServices';



interface SignInBody {
    [key: string]: any;
}

interface ResponseData {
    success: boolean;
    data: any;
    message: string;
}

interface RejectValue {
    message: string;
}

interface PlayersListData {
    skip: number;
    limit: number;
    sort: string;
    search?: string;
    minAge?: number;
    maxAge?: number;
    city?: string;
    status: string;
}
export interface PlayerListData {
    _id: string;
    skip: number;
    limit: number;
}


export const customerSignIn = createAsyncThunk<ResponseData, SignInBody, { rejectValue: RejectValue }>(
    'customer/signIn',
    async (body, { rejectWithValue }) => {
        try {
            const response = await AuthServices.customerSignIn(body);
            const { data } = response; // Destructure data from response

            if (!data.success) {
                return rejectWithValue({ message: data.message });
            }

            return data;
        } catch (error) {
            const { message = "An error occurred during login" } = error as { message?: string }; // Destructure message from error
            return rejectWithValue({ message });
        }
    }
);

export const customerLogout = createAsyncThunk<ResponseData, void, { rejectValue: RejectValue }>('customer/logout', async (_, { rejectWithValue }) => {
    try {
        const { data } = await AuthServices.customerLogout();
        return data;
    } catch (error: any) {
        return rejectWithValue({ message: error.message });
    }
}
);

export const getAllPlayerList = createAsyncThunk<ResponseData, PlayersListData, { rejectValue: RejectValue }>('playerList/fetchPlayerList', async (params: PlayersListData, { rejectWithValue }) => {
    try {
        const response = await PlayerListService.getAllPlayerList(params);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data || "Failed to fetch player list");
    }
}
);
export const getPlayerList = createAsyncThunk<ResponseData, PlayerListData, { rejectValue: RejectValue }>('playerList/fetchPlayerList', async (params: PlayerListData, { rejectWithValue }) => {
    try {
        const response = await PlayerListService.getPlayerList(params);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data || "Failed to fetch player list");
    }
}
);

export interface CommunityListData {
    _id: string;
    skip: number;
    limit: number;
}

interface Community {
    skip: number;
    limit: number;
    sort?: {
        createdAt?: number;
        earning?: number;
        deposite?: number;
        active?: number;
    };
    search?: string;
    city?: string;
    [key: string]: any;
}
export interface CommunitysListData {
    skip: number;
    limit: number;
    sort?: {
        createdAt?: number;
        earning?: number;
        deposite?: number;
        active?: number;
    };
    search?: string;
    city?: string;
}

// Define the CommunityListResponseData type
export interface CommunityListResponseData {
    success: boolean;
    data: any;
    message: string;
}

export const getAllCommunityList = createAsyncThunk<{ data: Community[]; countDocuments: number }, CommunitysListData, { rejectValue: RejectValue }>('communityList/fetchCommunityList', async (params, { rejectWithValue }) => {
    try {
        const response = await communityListServices.getAllCommunityList(params);
        return response.data; // Ensure this returns the right shape
    } catch (error: any) {
        return rejectWithValue({ message: error.response?.data?.message || "Failed to fetch community list" });
    }
});

export const getCommunityList = createAsyncThunk<CommunityListResponseData, CommunityListData, { rejectValue: RejectValue }>('communityList/fetchCommunityList',
    async (params, { rejectWithValue }) => {
        try {
            const response = await communityListServices.getCommunityList(params);
            return response.data;
        } catch (error: any) {
            return rejectWithValue({ message: error.response?.data?.message || "Failed to fetch community list" });
        }
    }
);



export interface AdminTeamResponseData {
    success: boolean;
    data: any;
    message: string;
}
export const getAdminTeam = createAsyncThunk<AdminTeamResponseData, { communityId: string }, { rejectValue: { message: string } }>('communityList/fetchAdminTeam', async ({ communityId }, { rejectWithValue }) => {
    try {
        const response = await communityListServices.getAdminTeam(communityId);
        return response.data;
    } catch (error: any) {
        return rejectWithValue({ message: error.response?.data?.message || "Failed to fetch admin team" });
    }
}
);

export interface AdvertisementParams {
    _id: string;
    type: string;
    skip: number;
    limit: number;
}

export interface AdvertisementListResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}
export interface SponsorshipListResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}
export interface AdvertisementList {
    search?: string;
    status?: string;
    sort?: {
        dailyBudget?: number;
        status?: number;
        createdAt?: number;
    };

}


export const getAdvertisementList = createAsyncThunk<AdvertisementListResponseData, AdvertisementList>('advertisements/fetchAdvertisementList', async (params: AdvertisementList) => {
    const response = await AdvertisementService.getAdvertisementList(params);
    return response.data;
}
);

export const getSponsorshipList = createAsyncThunk<SponsorshipListResponseData, AdvertisementParams>('advertisements/fetchSponsorshipList', async (params: AdvertisementParams) => {
    const response = await AdvertisementService.getSponsorshipList(params);
    return response.data;
}
);

// export const updateAdvertisement = createAsyncThunk('advertisements/updateAdvertisement',async (data: { id: string; status: string }) => {
//         const response = await AdvertisementService.updateAdvertisement(data);
//         return response.data; // Assuming the response contains the updated advertisement directly
//     }
// );
export const updateAdvertisement = createAsyncThunk('advertisements/updateAdvertisement', async (data: { id: string; status: string }, { rejectWithValue }) => {
    try {
        const response = await AdvertisementService.updateAdvertisement(data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});



export interface DashboardTotalResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}
export const getDashboardTotal = createAsyncThunk<DashboardTotalResponseData>('dashboard/fetchDashboardTotal', async () => {
    const response = await DashboardServices.getDashboardTotal();
    return response.data; // Assuming response.data contains the data
}
);

export interface CommunityDetailsResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

export const getCommunityDetails = createAsyncThunk<CommunityDetailsResponseData>('community/fetchCommunityDetails', async () => {
    const response = await CommunityDetailsService.getCommunityDetails();
    return response.data; // Assuming response.data contains the data
}
);


export interface TournamentRequestData {
    sort: {
        createdAt: number;
    };

    skip: number;
    limit: number;
    communityId: string;

}

export interface TournamentResponseData {
    success: boolean;
    data: any; // Adjust according to the actual response data structure
    message: string;
}

export const getTournaments = createAsyncThunk<TournamentResponseData, TournamentRequestData, { rejectValue: RejectValue }>("tournament/fetchTournaments", async (params, { rejectWithValue }) => {
    try {
        const response = await TournamentService.getTournaments(params);
        return response.data; // Adjust according to your API response shape
    } catch (error: any) {
        return rejectWithValue({ message: error.response?.data?.message || "Failed to fetch tournaments" });
    }
}
);

export interface FollowerFollowingListParams {
    userId: string;
    type: 'follower' | 'following'; // Ensure type is correctly restricted
    skip: number;
    limit: number;
}


export interface User {
    firstName: string;
    lastName: string;
    profile_image: string;
    status: boolean;
    userName: string;
    _id: string;
}

export interface FollowerFollowingListResponseData {
    success: boolean;
    data: {
        follower: User[];
        following: User[];
    };
    message: string;
}



export const getFollowerFollowingList = createAsyncThunk<FollowerFollowingListResponseData, FollowerFollowingListParams, { rejectValue: { message: string } }>('followerFollowing/fetchList', async (params, { rejectWithValue }) => {
    try {
        const response = await FollowerFollowingService.getFollowerFollowingList(params);
        return response.data;
    } catch (error: any) {
        return rejectWithValue({
            message: error.response?.data?.message || 'Failed to fetch follower/following list'
        });
    }
}
);






export interface PlayersDetailsResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}


export const getPlayersDetails = createAsyncThunk<PlayersDetailsResponseData>('player/getPlayersDetails', async () => {
    const response = await PlayersDetailsServices.getPlayersDetails();
    return response.data; // Assuming response.data contains the data
}
);

export interface UserIdData {
    userId: string;
}


export const getFeedPostStory = createAsyncThunk('user/getFeedPostStory', async ({ userId }: UserIdData, { rejectWithValue }) => {
    try {
        const response = await UserPlayerService.getFeedPostStory({ userId });
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue("An unexpected error occurred");
    }
}
);

export const getEventBadge = createAsyncThunk('user/getEventBadge', async ({ userId }: UserIdData, { rejectWithValue }) => {
    try {
        const response = await UserPlayerService.getEventBadge({ userId });
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue("An unexpected error occurred");
    }
}
);

export const getMessage = createAsyncThunk('user/getMessage', async ({ userId }: UserIdData, { rejectWithValue }) => {
    try {
        const response = await UserPlayerService.getMessage({ userId });
        return response.data;
    } catch (error: any) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        }
        return rejectWithValue("An unexpected error occurred");
    }
}
);


export interface FollowerFollowingListPlayerParams {
    userId: string;
    type: string;
    skip: number;
    limit: number;
    userType: string;

}


export const getFollowerFollowingListPlayer = createAsyncThunk("followerFollowing/fetchFollowerFollowingListPlayer", async (params: FollowerFollowingListPlayerParams, { rejectWithValue }) => {
    try {
        const response = await FollowerFollowingPlayersService.getFollowerFollowingListPlayer(params);
        return response.data;
    } catch (error: any) {
        // Handle error and return it to the rejected state
        return rejectWithValue(error.response?.data || error.message);
    }
}
);

export interface FollowerFollowing {
    userId: string;
    type: string;
    skip: number;
    limit: number;
}


export const getFollowerFollowing = createAsyncThunk("followerFollowing/fetchFollowerFollowingListPlayer", async (params: FollowerFollowing, { rejectWithValue }) => {
    try {
        const response = await FollowerFollowing.getFollowerFollowing(params);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data || error.message);
    }
}
);
export interface AgeGenderStatistics {
    period: string;
}

export const getAgeGenderStatistics = createAsyncThunk('ageGenderStatistics/fetch', async (data: AgeGenderStatistics, { rejectWithValue }) => {
    try {
        const response = await AgeGenderStatisticsService.getAgeGenderStatistics(data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data || error.message);
    }
}
);

export interface TopCitiesWithHighest {
    period: string;
}

export const getTopCitiesWithHighest = createAsyncThunk('ageGenderStatistics/fetch', async (data: TopCitiesWithHighest, { rejectWithValue }) => {
    try {
        const response = await TopCitiesWithHighestServices.getTopCitiesWithHighest(data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data || error.message);
    }
}
);



export interface AllTournamentListRequestData {
    skip: number;
    limit: number;
    sort?: {
        createdAt?: number;

    };
    search?: string;
    city?: string;
}

export interface TournamentListRequestData {
    id: string;
    skip: number;
    limit: number;
}


export interface TournamentListResponseData {
    success: boolean;
    data: any;
    message: string;
}




export const getAllTournamentsList = createAsyncThunk<{ data: AllTournamentListRequestData[]; countDocuments: number }, AllTournamentListRequestData, { rejectValue: RejectValue }>('tournament/fetchTournaments', async (params, { rejectWithValue }) => {
    try {
        const response = await TournamentListServices.getAllTournamentsList(params);
        return response.data; // Ensure this returns the right shape
    } catch (error: any) {
        return rejectWithValue({ message: error.response?.data?.message || "Failed to fetch community list" });
    }
});

export const getTournamentsList = createAsyncThunk<{ data: TournamentListRequestData[]; countDocuments: number }, TournamentListRequestData, { rejectValue: RejectValue }>('tournament/fetchTournaments', async (params, { rejectWithValue }) => {
    try {
        const response = await TournamentListServices.getTournamentsList(params);
        return response.data; // Ensure this returns the right shape
    } catch (error: any) {
        return rejectWithValue({ message: error.response?.data?.message || "Failed to fetch community list" });
    }
});


export interface TournamentPointsTable {
    tournamentId: string;
}

export const getTournamentPointsTable = createAsyncThunk("tournament/getPointsTable", async (data: TournamentPointsTable, { rejectWithValue }) => {
    try {
        const response = await TournamentPointsTableServices.getTournamentPointsTable(data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data || error.message);
    }
}
);

export interface TouenamentPrizeCard {
    tournamentId: string;
}


export const getTouenamentPrizeCard = createAsyncThunk("tournament/getPointsTable", async (data: TouenamentPrizeCard, { rejectWithValue }) => {
    try {
        const response = await TouenamentPrizeCardServices.getTouenamentPrizeCard(data);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response?.data || error.message);
    }
}
);
export interface TournamentJoinTeams {
    tournamentId: string;
}

export const getAllJoinTeamsOfTournament = createAsyncThunk('tournament/getAllJoinTeamsOfTournament', async (data: TournamentJoinTeams, thunkAPI) => {
    try {
        const response = await AllJoinTeamsOfTournamentServices.getAllJoinTeamsOfTournament(data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
}
);

export const getTournamentsStages = createAsyncThunk('tournament/getTournamentsStages', async (data: TournamentJoinTeams, thunkAPI) => {
    try {
        const response = await AllJoinTeamsOfTournamentServices.getTournamentsStages(data);
        return response.data;
    } catch (error: any) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
}
);

// Action to create a rule
export const createRule = createAsyncThunk('rule/create', async (ruleData: { title: string; description: string }, { rejectWithValue }) => {
    try {
        const response = await RuleServices.createRule(ruleData);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

// Action to get a rule by ID
export const getRule = createAsyncThunk('rule/get', async (params: { search: string }, { rejectWithValue }) => {
    try {
        const response = await RuleServices.getRule(params);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

// Action to edit a rule by ID
export const editRule = createAsyncThunk('rule/edit', async (ruleData: { title: string; id: string; description: string }, { rejectWithValue }) => {
    try {
        const response = await RuleServices.editRule(ruleData);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

// Action to delete a rule by ID
export const deleteRule = createAsyncThunk('rule/delete', async (params: { id: string }, { rejectWithValue }) => {
    try {
        const response = await RuleServices.deleteRule(params);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export interface SponsorshipRequestData {
    search: string;
    communityId: string;
    sort: {
        createdAt: number;
        status: number;
    };
    skip: number;
    limit: number;
}

export const fetchSponsorshipAds = createAsyncThunk('sponsorship/fetchSponsorshipAds', async (requestData: SponsorshipRequestData, { rejectWithValue }) => {
    try {
        const response = await SponsorshipServices.getSponsorshipAds(requestData);
        return response.data; // Payload returned to sponsorshipSlice
    } catch (error: any) {
        return rejectWithValue(error.response.data); // In case of error
    }
}
);
export interface UpdateCommunityData {
    id: string;
    sponsorshipRates: number;
    [key: string]: any; // For other updateable fields
} 

export const updateCommunity = createAsyncThunk<CommunityListResponseData, UpdateCommunityData, { rejectValue: RejectValue }>('communityList/updateCommunity', async (params, { rejectWithValue }) => {
    try {
        const response = await communityListServices.updateCommunity(params);
        return response.data;
    } catch (error: any) {
        return rejectWithValue({ message: error.response?.data?.message || "Failed to update community" });
    }
});
