import requests from "../Axios/Instance";

export interface RuleData {
    search?: string;
    ruleData?: any; // Object for POST/PUT data
}

const RuleServices = {
    // Create a new rule by passing title and description dynamically
    createRule: async (ruleData: { title: string; description: string }) => {
        return requests.post('/tournament/createRule', ruleData);
    },
    
    // Get a rule by its dynamic search and id
    getRule: async (data: RuleData) => {
        return requests.get(`/tournament/getRule?search=${data.search}`);
    },

    // Edit a rule by passing dynamic ID and updated rule data
    editRule: async ( ruleData: { title: string; id: string; description: string }) => {
        return requests.put('/tournament/editRule', ruleData);
    },

    // Delete a rule by passing its dynamic ID
    deleteRule: async (ruleData: { id: string }) => {
        return requests.delete(`/tournament/deleteRule?id=${ruleData.id}`);
    },
};

export default RuleServices;
