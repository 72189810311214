import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllCommunityList, updateCommunity} from "../actions";

interface Community {
    skip: number;
    limit: number;
    sort?: {
        createdAt?: number;
        earning?: number;
        deposite?: number;
        active?: number;
    };
    search?: string;
    city?: string;
    [key: string]: any;
}

export interface CommunityListResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

interface CommunityListState {
    communities: Community[];
    countDocuments: number;
    loading: boolean;
    error: string | null;
}

const initialState: CommunityListState = {
    communities: [],
    countDocuments: 0,
    loading: false,
    error: null,
};

const communityListSlice = createSlice({
    name: "communityList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCommunityList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllCommunityList.fulfilled, (state, action: PayloadAction<{ data: Community[]; countDocuments: number; }>) => {
                state.loading = false;
                state.communities = action.payload.data;
                state.countDocuments = action.payload.countDocuments;
            })
            .addCase(getAllCommunityList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to fetch community list";
            })
            .addCase(updateCommunity.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCommunity.fulfilled, (state, action: PayloadAction<CommunityListResponseData>) => {
                state.loading = false;
                // Update the specific community in the state
                const updatedCommunity = action.payload.data;
                const index = state.communities.findIndex(c => c._id === updatedCommunity._id);
                if (index !== -1) {
                    state.communities[index] = updatedCommunity;
                }
            })
            .addCase(updateCommunity.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload?.message || "Failed to update community";
            });
    },
});

export default communityListSlice.reducer;
