import requests from "../Axios/Instance";

export interface TournamentPointsTable {
  tournamentId: string;
}

const TournamentPointsTableServices = {
  getTournamentPointsTable: async (data: TournamentPointsTable) => {
    return requests.get(`/tournament/getPointsTable?tournamentId=${data.tournamentId}`);
  },
};

export default TournamentPointsTableServices;
