// SponsorshipServices.ts
import requests from "../Axios/Instance";

// Define the SponsorshipRequestData interface for the request body
export interface SponsorshipRequestData {
    search: string;
    communityId: string;
    sort: {
        createdAt: number;
        status: number;
    };
    skip: number;
    limit: number;
}

 
// Define the SponsorshipResponseData interface for the response
export interface SponsorshipResponseData {
    success: boolean;
    data: any; // Adjust this type based on the actual response
    message: string;
}

const SponsorshipService = {
    getSponsorshipAds: async (data: SponsorshipRequestData) => {
        return requests.post<SponsorshipResponseData>(`/sponsorship/getSponsorshipAds`, data);
    },
};

export default SponsorshipService;
