// sponsorshipSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSponsorshipAds } from '../actions';

export interface SponsorshipRequestData {
    search: string;
    communityId: string;
    sort: {
        createdAt: number;
        status: number;
    };
    skip: number;
    limit: number;
}

export interface SponsorshipResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

interface SponsorshipState {
    ads: any[]; // Adjust the type based on actual data structure
    loading: boolean;
    error: string | null;
}

const initialState: SponsorshipState = {
    ads: [],
    loading: false,
    error: null,
};

const sponsorshipSlice = createSlice({
    name: 'sponsorship',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Handle pending state
        builder.addCase(fetchSponsorshipAds.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        // Handle fulfilled state
        builder.addCase(fetchSponsorshipAds.fulfilled, (state, action: PayloadAction<SponsorshipResponseData>) => {
            state.ads = action.payload.data;
            state.loading = false;
        });
        // Handle rejected state
        builder.addCase(fetchSponsorshipAds.rejected, (state, action: PayloadAction<any>) => {
            state.error = action.payload?.message || 'Failed to fetch sponsorship ads';
            state.loading = false;
        });
    },
});

export default sponsorshipSlice.reducer;
