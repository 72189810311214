import { createSlice } from '@reduxjs/toolkit';
import { createRule, getRule, editRule, deleteRule } from '../actions';

export interface RuleState {
    rule: any;
    loading: boolean;
    error: string | null;
}

const initialState: RuleState = {
    rule: null,
    loading: false,
    error: null,
};

const ruleSlice = createSlice({
    name: 'rule',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Create Rule
        builder.addCase(createRule.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(createRule.fulfilled, (state, action) => {
            state.loading = false;
            state.rule = action.payload;
        });
        builder.addCase(createRule.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Get Rule
        builder.addCase(getRule.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getRule.fulfilled, (state, action) => {
            state.loading = false;
            state.rule = action.payload;
        });
        builder.addCase(getRule.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Edit Rule
        builder.addCase(editRule.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(editRule.fulfilled, (state, action) => {
            state.loading = false;
            state.rule = action.payload;
        });
        builder.addCase(editRule.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });

        // Delete Rule
        builder.addCase(deleteRule.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(deleteRule.fulfilled, (state) => {
            state.loading = false;
            state.rule = null; // Reset rule after deletion
        });
        builder.addCase(deleteRule.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

export default ruleSlice.reducer;
