import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTournamentPointsTable } from "../actions";

// Define an interface for the state
interface TournamentPointsTableState {
  pointsTable: any; // Define the appropriate type based on your API response
  loading: boolean;
  error: string | null;
}

const initialState: TournamentPointsTableState = {
  pointsTable: null,
  loading: false,
  error: null,
};

const TournamentPointsTableSlice = createSlice({
  name: "tournamentPointsTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTournamentPointsTable.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(getTournamentPointsTable.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.pointsTable = action.payload; // Update the state with the response data
      })
      .addCase(getTournamentPointsTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch points table"; // Handle error
      });
  },
});

// Export the actions and the reducer
export default TournamentPointsTableSlice.reducer;
