// src/slices/advertisementSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAdvertisementList, getSponsorshipList, updateAdvertisement } from '../actions';

// Define the structure of a single advertisement
interface Advertisement {
    _id: string;
    type: string;
    // Include any other relevant fields from your advertisement model
}

interface AdvertisementListState {
    advertisements: Advertisement[];
    loading: boolean;
    error: string | null;
}

const initialState: AdvertisementListState = {
    advertisements: [],
    loading: false,
    error: null,
};

// Create slice
const advertisementSlice = createSlice({
    name: 'advertisements',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdvertisementList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAdvertisementList.fulfilled, (state, action: PayloadAction<{ data: Advertisement[] }>) => {
                state.loading = false;
                state.advertisements = action.payload.data;
            })
            .addCase(getAdvertisementList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch advertisements';
            })
            .addCase(getSponsorshipList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSponsorshipList.fulfilled, (state, action: PayloadAction<{ data: Advertisement[] }>) => {
                state.loading = false;
                state.advertisements = action.payload.data;
            })
            .addCase(getSponsorshipList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch sponsorships';
            })
            .addCase(updateAdvertisement.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateAdvertisement.fulfilled, (state, action: PayloadAction<Advertisement>) => {
                state.loading = false;
                const updatedAdvertisement = action.payload; // This should now be a single Advertisement object
                const index = state.advertisements.findIndex(ad => ad._id === updatedAdvertisement._id);
                if (index !== -1) {
                    state.advertisements[index] = updatedAdvertisement; // This updates the existing advertisement
                }
            })
            .addCase(updateAdvertisement.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to update advertisement';
            });
    },
});

export default advertisementSlice.reducer;
