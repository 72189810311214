import requests from "../Axios/Instance";

export interface  TouenamentPrizeCard {
  tournamentId: string;
}

const TouenamentPrizeCardServices = {
  getTouenamentPrizeCard: async (data: TouenamentPrizeCard) => {
    return requests.get(`/tournament/getPrizeCard?tournamentId=${data.tournamentId}`);
  },
};

export default TouenamentPrizeCardServices;
