import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTouenamentPrizeCard } from "../actions";

// Define an interface for the state
interface TournamentPrizeCard {
    PrizeCard: any; // Define the appropriate type based on your API response
    loading: boolean;
    error: string | null;
}

const initialState: TournamentPrizeCard = {
    PrizeCard: null,
    loading: false,
    error: null,
};

const TouenamentPrizeCardSlice = createSlice({
    name: "tournamentPrizeCard",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTouenamentPrizeCard.pending, (state) => {
                state.loading = true;
                state.error = null; // Reset error on new request
            })
            .addCase(getTouenamentPrizeCard.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.PrizeCard = action.payload; // Update the state with the response data
            })
            .addCase(getTouenamentPrizeCard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || "Failed to fetch points table"; // Handle error
            });
    },
});

// Export the actions and the reducer
export default TouenamentPrizeCardSlice.reducer;
