import { createSlice } from '@reduxjs/toolkit';
import { getAllJoinTeamsOfTournament ,getTournamentsStages } from '../actions';

// Interface for the state
interface AllJoinTeamsOfTournamentState {
    teams: any; // Define the appropriate type based on your API response
    stages: any;
    loading: boolean;
    error: string | null;
}

// Initial state
const initialState: AllJoinTeamsOfTournamentState = {
    teams: null,
    stages:null,
    loading: false,
    error: null,
};



// Slice
const AllJoinTeamsOfTournamentSlice = createSlice({
    name: 'allJoinTeamsOfTournament',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Pending
        builder.addCase(getAllJoinTeamsOfTournament.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        // Fulfilled
        builder.addCase(getAllJoinTeamsOfTournament.fulfilled, (state, action) => {
            state.teams = action.payload;
            state.loading = false;
        });
        // Rejected
        builder.addCase(getAllJoinTeamsOfTournament.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        builder.addCase(getTournamentsStages.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        // Fulfilled
        builder.addCase(getTournamentsStages.fulfilled, (state, action) => {
            state.stages = action.payload;
            state.loading = false;
        });
        // Rejected
        builder.addCase(getTournamentsStages.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
    },
});

// Export the reducer to be used in the store
export default AllJoinTeamsOfTournamentSlice.reducer;
