import requests from "../Axios/Instance";

export interface CommunityListData {
    _id: string;
    skip: number;
    limit: number;
}

export interface CommunitysListData {
    skip: number;
    limit: number;
    sort?: {
        createdAt?: number;
        earning?: number;
        deposite?: number;
        active?: number;
    };
    search?: string;
    city?: string;
}

export interface UpdateCommunityData {
    id: string;
    sponsorshipRates: number;
    [key: string]: any; // For other updateable fields
}

// Define the CommunityListResponseData type
export interface CommunityListResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}
export interface AdminTeamResponseData {
    success: boolean;
    data: any; // Adjust according to your actual response data
    message: string;
}

const CommunityListService = {
    getAllCommunityList: async (data: CommunitysListData) => {
        return requests.post(`/community/communityList`, data);
    },
    getCommunityList: async (data: CommunityListData) => {
        return requests.post(`/community/communityList`, data);
    },
    getAdminTeam: async (communityId: string) => {
        return requests.get(`/community/getAdminTeam?communityId=${communityId}`);
    },
    updateCommunity: async (data: UpdateCommunityData) => {
        return requests.put(`/community/updateCommunity`, data);
    },
};

export default CommunityListService;
