import requests from "../Axios/Instance";

// Interface for passing the tournament ID
export interface TournamentJoinTeams {
  tournamentId: string;
}

// Service for fetching all joined teams of a tournament
const AllJoinTeamsOfTournamentServices = {
  getAllJoinTeamsOfTournament: async (data: TournamentJoinTeams) => {
    return requests.get(`/tournament/getAllJoinTeamsOfTournament?tournamentId=${data.tournamentId}`);
  },
  getTournamentsStages: async (data: TournamentJoinTeams) => {
    return requests.get(`/tournament/getTournamentsStages?tournamentId=${data.tournamentId}`);
  },
};

export default AllJoinTeamsOfTournamentServices;
