// src/services/AdvertisementService.ts
import requests from "../Axios/Instance";

export interface AdvertisementParams {
    _id: string;
    type: string;
    skip: number;
    limit: number;
}
export interface AdvertisementList {
    search?: string;
    status?: string;
    sort?: {
        dailyBudget?: number;
        status?: number;
        createdAt?: number;
    };
}

const AdvertisementService = {
    getAdvertisementList: async (data: AdvertisementList) => {
        return requests.post(`/advertisement/advertList`, data);
    },
    getSponsorshipList: async (data: AdvertisementParams) => {
        return requests.get(`/advertisement/sponsorshipList?_id=${data._id}&skip=${data.skip}&type=${data.type}&limit=${data.limit}`);
    },
    updateAdvertisement: async (data: { id: string; status: string }) => {
        return requests.put(`/advertisement/editAdDetails`, data);
    },
};

export default AdvertisementService;
